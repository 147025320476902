<!-- 结算期限 -->
<template>
  <div class="wrap" v-loading="loading">
    <TablePage ref="tablePage"  v-model="options" @handleEvent="handleEvent" />
    <Dialog
      ref="dialogTablePage"
      :options.sync="dialogOptions"
      @handleEvent="handleEvent"
    />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import Dialog from '@/components/Dialog'

import { 
  updateReconStatus,
  listSettlementTerm,
  addSettlementTerm,
  modifySettlementTerm,
  getSettlementTerm
} from "@/api/system/settlementTerm/index";

export default {
  components: { TablePage, Dialog },
  data() {
    return {
      isEdit: true,
      loading: false,
      //弹窗配置
      dialogOptions: {
        title: '选择',
        width: 1100,
        show: false,
        type: 'TreeAndTable'
      },
      options: {
        text: '',
        mutiSelect: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        getListApi: listSettlementTerm,
        body: {},
        listNo: true,
        title: "单据信息",
        check: [],
        rowKey: "billId",
        defaultBody: {
          rpTypeCategoryIds: '',
          rpType: 2,
        },
        search: [
          {
            type: "filters",
            tip: "全部/支出编码/支出名称",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "rpTypeNos", label: "支出编码" },
              { filter: "rpTypeNames", label: "支出名称" },
            ],
          },
          this.$select({ key: "isStopState",option: { seniorSearch: true }}),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "settlementTermNo",
            label: "编码",
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: "settlementTermName",
            label: "名称",
            minWidth: 155,
          },
          {
            prop: "settlementTermDays",
            label: "期限",
            minWidth: 180,
          },
          {
            prop: "settlementCheckAccountDay",
            label: "对账日",
            minWidth: 155,
          },
          {
            prop: "settlementCheckAccountDay",
            label: "对账日",
            minWidth: 155,
          },
          {
            prop: "settlementCheckAccountDay",
            label: "对账日",
            minWidth: 155,
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 155,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 155,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 155,
          },
          {
            prop: "updateBy",
            label: "更新人",
            minWidth: 155,
          },
          {
            prop: "updateTime",
            label: "更新时间",
            minWidth: 155,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    handleNodeClick(data) {
      console.log(data);
      this.options.defaultBody.rpTypeCategoryIds = [data.rpTypeCategoryId]
      this.$refs.tablePage.getList();
    },
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case 'SourceDialogChange': 
          let urlObj = row.settlementTermId? modifySettlementTerm : addSettlementTerm
          urlObj(row).then(res => {
            this.$message.success( row.settlementTermId? '修改成功' : '添加成功')
            this.dialogOptions.show = false
            this.$refs.tablePage.getList();
          })
        break;
        case "routerLink":
          {
            let params = {
              settlementTermId: row.settlementTermId
            }
            let data = await getSettlementTerm(params)
            this.dialogOptions = {
              show: true,
              type: "SettlementTermOperationList",
              click: 'SettlementTermOperationList',
              data: {
                obj: data.data,
              },
              title: "修改结算期限",
            };
          }
          break;
        case "add":
          {
            this.dialogOptions = {
              show: true,
              type: "SettlementTermOperationList",
              click: 'SettlementTermOperationList',
              data: { 
                obj:{
                  settlementTermNo: undefined,
                  settlementTermName: undefined,
                  settlementTermDays: undefined,
                  settlementCheckAccountDay: undefined,
                  sortNo: undefined,
                },
              },
              title: "添加结算期限",
            };
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData, '/api/system/finance/settlement/term/delSettlementTerm');
          }
          break;
        default:
          break;
      }
    },
    async handleBillStatus(command, selectData, url) {
      const settlementTermIds = selectData.map((i) => i.settlementTermId);
      const $this = this;
      let operate = await this.$modal.confirm(`是否确认${command}`)
      let params = { settlementTermIds: [...settlementTermIds], url: url }
      console.log(params, 'ams');
      updateReconStatus(params).then(() => {
        $this.$nextTick(() => { $this.options.check = [] });
        $this.$modal.msgSuccess(`${command}成功`);
        setTimeout(() => {
          $this.$refs.tablePage.getList();
        }, 500);
      });
    },
  },
  mounted () {},
};
</script>
<style lang="scss" scoped>
</style>
