var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingTable,
          expression: "loadingTable",
        },
      ],
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.priceListForm, rules: _vm.rules } },
        [
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "查询", id: "search-card" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "navSearch" },
                  [
                    _c("seniorSearch", {
                      attrs: {
                        getList: _vm.getList,
                        dropDownList: _vm.dropDownList,
                        isBillDate: false,
                        isSearchInput: true,
                        isSearchInputTitle: "输入商品编码、名称、条码查询",
                      },
                      on: { isShowHighCom: _vm.getIsShowHigh },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showHigh,
                            expression: "showHigh",
                          },
                        ],
                        staticClass: "searchCriteria",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "marT10" },
                          [
                            _c("span", { staticClass: "fS14MR10" }, [
                              _vm._v("商品类别"),
                            ]),
                            _c("SelectRemote", {
                              staticClass: "marR15",
                              staticStyle: { width: "250px" },
                              attrs: {
                                multiple: true,
                                option: _vm.$select({ key: "listCategory" })
                                  .option,
                              },
                              model: {
                                value: _vm.queryParams.categoryIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "categoryIds", $$v)
                                },
                                expression: "queryParams.categoryIds",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "priceList", attrs: { cardTitle: "商品信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent" },
                  [
                    _c("operatingButton", {
                      attrs: {
                        getList: _vm.getList,
                        isAddBtn: false,
                        isDeleteBtn: false,
                      },
                      on: { handRefresh: _vm.getList },
                      scopedSlots: _vm._u([
                        {
                          key: "specialDiyBtn",
                          fn: function () {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticClass: "iconfont icon-qingchu",
                                  attrs: { size: "mini", type: "primary" },
                                  on: { click: _vm.getClearPrice },
                                },
                                [_vm._v(" 清除手工价格 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: { click: _vm.submitForm },
                                },
                                [_vm._v("保存 ")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "el-table",
                      {
                        ref: "multipleTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          "tooltip-effect": "dark",
                          data: _vm.priceListForm.tableData,
                          border: "",
                          "max-height": _vm.tableHeight,
                          height: _vm.tableHeight,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "序号",
                            align: "center",
                            width: "80",
                            type: "index",
                            fixed: "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            "show-overflow-tooltip": "",
                            label: "商品编码",
                            align: "center",
                            prop: "goodsNo",
                            "min-width": "150",
                            fixed: "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品名称",
                            align: "center",
                            prop: "goodsName",
                            "min-width": "120",
                            fixed: "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "条码",
                            align: "center",
                            prop: "barcode",
                            "min-width": "120",
                            fixed: "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "类别编码",
                            align: "center",
                            prop: "categoryNo",
                            "min-width": "120",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "类别名称",
                            align: "center",
                            prop: "categoryName",
                            "min-width": "120",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "规格",
                            align: "center",
                            prop: "goodsSpec",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "单位",
                            align: "center",
                            prop: "unitName",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "最新进价",
                            align: "center",
                            prop: "lastPurNotaxPrice",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品进价",
                            align: "center",
                            prop: "purPrice",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品售价",
                            align: "center",
                            prop: "salePrice",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: { align: "center", width: "1" },
                        }),
                        _vm.priceCaseList.length > 0
                          ? _c(
                              "div",
                              _vm._l(_vm.priceCaseList, function (item, index) {
                                return _c(
                                  "div",
                                  { key: item.priceCaseId },
                                  [
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          align: "center",
                                          prop: "priceCaseName",
                                          label: item.priceCaseName,
                                          width: "155",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              align: "center",
                                              prop: "name",
                                              label: item.priceTypeName,
                                              width: "155",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      scope.row
                                                        .basGoodsPriceCaseHandVos[
                                                        index
                                                      ].handPrice != undefined
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "red-text",
                                                            },
                                                            [
                                                              _c(
                                                                "el-form-item",
                                                                {
                                                                  attrs: {
                                                                    prop:
                                                                      "tableData." +
                                                                      scope.$index +
                                                                      ".basGoodsPriceCaseHandVos." +
                                                                      index +
                                                                      ".handPrice",
                                                                    rules:
                                                                      _vm.rules[
                                                                        "tableData.handPrice"
                                                                      ],
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          item.specialFlag ==
                                                                          "*",
                                                                        type: "number",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handPriceChange(
                                                                              scope
                                                                                .row
                                                                                .goodsId,
                                                                              index,
                                                                              scope
                                                                                .row
                                                                                .basGoodsPriceCaseHandVos[
                                                                                index
                                                                              ]
                                                                                .handPrice,
                                                                              scope
                                                                                .row
                                                                                .basGoodsPriceCaseHandVos[
                                                                                index
                                                                              ]
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          scope
                                                                            .row
                                                                            .basGoodsPriceCaseHandVos[
                                                                            index
                                                                          ]
                                                                            .handPrice,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              scope
                                                                                .row
                                                                                .basGoodsPriceCaseHandVos[
                                                                                index
                                                                              ],
                                                                              "handPrice",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                              scope.row.basGoodsPriceCaseHandVos[index]\n                                .handPrice\n                            ",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "el-form-item",
                                                                {
                                                                  attrs: {
                                                                    prop:
                                                                      "tableData." +
                                                                      scope.$index +
                                                                      ".basGoodsPriceCaseHandVos." +
                                                                      index +
                                                                      ".standardPrice",
                                                                    rules:
                                                                      _vm.rules[
                                                                        "tableData.standardPrice"
                                                                      ],
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          item.specialFlag ==
                                                                          "*",
                                                                        type: "number",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.standardPriceChange(
                                                                              scope
                                                                                .row
                                                                                .goodsId,
                                                                              index,
                                                                              scope
                                                                                .row
                                                                                .basGoodsPriceCaseHandVos[
                                                                                index
                                                                              ]
                                                                                .standardPrice,
                                                                              scope
                                                                                .row
                                                                                .basGoodsPriceCaseHandVos[
                                                                                index
                                                                              ]
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          scope
                                                                            .row
                                                                            .basGoodsPriceCaseHandVos[
                                                                            index
                                                                          ]
                                                                            .standardPrice,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              scope
                                                                                .row
                                                                                .basGoodsPriceCaseHandVos[
                                                                                index
                                                                              ],
                                                                              "standardPrice",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "\n                              scope.row.basGoodsPriceCaseHandVos[index]\n                                .standardPrice\n                            ",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("template", { slot: "header" }, [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.priceTypeName)
                                                ),
                                              ]),
                                              _c("span", [_vm._v(" (")]),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.priceCalcType)
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  " " + _vm._s(item.priceValue)
                                                ),
                                              ]),
                                              _c("span", [_vm._v(")")]),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("bottomPage", {
                      staticClass: "marB10",
                      attrs: {
                        getList: _vm.getList,
                        totalCom: _vm.total,
                        isSelectAll: false,
                      },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }