<!-- 支出类别 -->
<template>
  <div class="wrap" v-loading="loading">
    <div class="content">
      <div class="left-tree">
        <div class="left-box">
          <div class="treeTitle">
            <span class="leftTitle">分类</span>
            <div>
              <span class="handleEdit" @click="isEdit = !isEdit">{{ isEdit? '编辑' : '完成编辑' }}</span>
            </div>
          </div>
          <div class="searchTreeBox">
            <el-input
              size="mini"
              class="searchTree"
              placeholder="输入关键字进行过滤"
              v-model="options.text"
              prefix-icon="el-icon-search"
            >
            </el-input>
          </div>
          <el-scrollbar>
            <el-tree :data="categoryList" :props="defaultProps" @node-click="handleNodeClick">
              <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <!--  && data.parentId !=0 -->
              <span v-if="!isEdit">
                <el-button
                  class="iconBtnTree"
                  icon="el-icon-circle-plus-outline"
                  type="text"
                  size="mini"
                  @click.native.stop="() => handleEvent('categoryAdd', data)"
                >
                </el-button>
                <el-button
                  class="iconBtnTree"
                  icon="el-icon-edit"
                  type="text"
                  size="mini"
                  @click.native.stop="() => handleEvent('categoryEdit', data)"
                >
                </el-button>
                <el-button
                  class="iconBtnTree"
                  icon="el-icon-delete"
                  type="text"
                  size="mini"
                  @click.native.stop="() => handleEvent('categoryDel', data)"
                  v-if="data.parentId !=0"
                >
                </el-button>
              </span>
            </span>
            </el-tree>
          </el-scrollbar>
        </div>
      </div>
      <div class="right-table">
        <TablePage ref="tablePage" v-if="showList" v-model="options" @handleEvent="handleEvent" />
      </div>
    </div>
    <Dialog
      ref="dialogTablePage"
      :options.sync="dialogOptions"
      @handleEvent="handleEvent"
    />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { listReceiveBill } from "@/api/arap/index";
import Dialog from '@/components/Dialog'



import { 
  listApCategory,
  getCategoryInfo,
  addApCategory,
  modifyApCategory,
  deleteCategory,

  listRpType,
  addTypeList,
  modifyTypeList,
  getRpTypeByRpTypeId,
  updateReconStatus

} from "@/api/system/category/index";
import { fcount } from '@/utils'

export default {
  components: { TablePage, Dialog },
  dicts: ["bill_status"],
  data() {
    return {
      isEdit: true,
      showList: false,
      categoryList: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      loading: false,
      //弹窗配置
      dialogOptions: {
        title: '选择',
        width: 1100,
        show: false,
        type: 'TreeAndTable'
      },
      options: {
        text: '',
        mutiSelect: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        getListApi: listRpType,
        body: {},
        listNo: true,
        title: "单据信息",
        check: [],
        rowKey: "billId",
        defaultBody: {
          rpTypeCategoryIds: ['1764545394310201344'],
          rpType: 2,
        },
        search: [
          {
            type: "filters",
            tip: "全部/支出编码/支出名称",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "rpTypeNos", label: "支出编码" },
              { filter: "rpTypeNames", label: "支出名称" },
            ],
          },
          this.$select({ key: "isStopState",option: { seniorSearch: true }}),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "recon",
            label: "启用",
            type: "success",
            btnType: "dropdown",
            other: [
              {
                click: "redoRecon",
                label: "禁用",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "rpTypeNo",
            label: "支出编码",
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: "rpTypeName",
            label: "支出名称",
            minWidth: 155,
          },
          {
            prop: "rpTypeName",
            label: "支出分类名称",
            minWidth: 180,
          },
          {
            prop: "rpTypeCategoryId",
            label: "支出分类编码",
            minWidth: 155,
          },
          {
            prop: "rpTypeCategoryName",
            label: "分类名称",
            formatter: (v, row) => row.rpTypeCategoryInfo?  row.rpTypeCategoryInfo.rpTypeCategoryName: '',
            minWidth: 120,
          },
          {
            prop: "isStop",
            label: "状态",
            formatter: (v, row) => row.isStop?  '禁用': '启用',
            minWidth: 120,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    getCategoryList (type) {
      if (type) {
        this.loading = true
      }
      listApCategory().then(res => {
        function buildTreeData(data) {
          const map = {};
          data.forEach((node) => {
            map[node.rpTypeCategoryId] = { ...node, label:node.rpTypeCategoryName, children: [] };
          });
          const tree = [];
          data.forEach((node) => {
            const parent = map[node.parentId];
            if (parent) {
              parent.children.push(map[node.rpTypeCategoryId]);
            } else {
              tree.push(map[node.rpTypeCategoryId]);
            }
          });
          return tree;
        }
        this.loading = false;
        let list = buildTreeData(res.data)
        console.log(res.data, 'res.data');
        console.log(list, 'list');
        this.options.defaultBody.rpTypeCategoryIds = [list[0].rpTypeCategoryId]
        this.showList = true
        this.categoryList = list
      })
    },
    handleNodeClick(data) {
      console.log(data);
      this.options.defaultBody.rpTypeCategoryIds = [data.rpTypeCategoryId]
      this.$refs.tablePage.getList();
    },
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "categoryAdd":
          {
            this.dialogOptions = {
              show: true,
              type: "CategoryOperation",
              click: 'CategoryOperation',
              data: { 
                type: 1,
                list: this.categoryList,
                obj: {},
                classificationType: 2,
              },
              title: "添加分类",
            };
          }
        case "categoryEdit":
          {
            let params = {
              rpTypeCategoryId: row.rpTypeCategoryId
            }
            let data = await getCategoryInfo(params)
            this.dialogOptions = {
              show: true,
              type: "CategoryOperation",
              click: 'CategoryOperation',
              data: { 
                type: 2,
                list: this.categoryList,
                obj: data.data,
                classificationType: 2,
              },
              title: "修改分类",
            };
          }
        break;
        case "categoryDel":
          {
            await this.$modal.confirm(`是否确认删除`)
            let params = {
              rpTypeCategoryId: row.rpTypeCategoryId
            }
            deleteCategory(params).then(res => {
              this.$message.success('删除成功')
              this.getCategoryList()
            })
          }
        break;
        case 'SourceDialogChange': 
          if (this.dialogOptions.type == 'CategoryOperationList') {
            let urlObj = row.rpTypeId? modifyTypeList : addTypeList
            urlObj(row).then(res => {
              this.$message.success( row.rpTypeId? '修改成功' : '添加成功')
              this.dialogOptions.show = false
              this.$refs.tablePage.getList();
            })
          } else {
            let url = row.rpTypeCategoryId? modifyApCategory : addApCategory
            url(row).then(res => {
              this.$message.success( row.rpTypeCategoryId? '修改成功' : '添加成功')
              this.dialogOptions.show = false
              this.getCategoryList()
            })
          }
        break;
        case "routerLink":
          {
            let params = {
              rpTypeId: row.rpTypeId
            }
            let data = await getRpTypeByRpTypeId(params)
            this.dialogOptions = {
              show: true,
              type: "CategoryOperationList",
              click: 'CategoryOperationList',
              data: { 
                type: 2,
                list: this.categoryList,
                obj: data.data,
                classificationType: 2,
              },
              title: "修改分类",
            };
          }
          break;
        case "add":
          {
            this.dialogOptions = {
              show: true,
              type: "CategoryOperationList",
              click: 'CategoryOperationList',
              data: { 
                type:1,
                list: this.categoryList,
                obj:{
                  rpTypeNo: '',
                  rpTypeName: '',
                  rpTypeCategoryId: '',
                },
                classificationType: 2,
              },
              title: "添加分类",
            };
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData, '/api/system/finance/rp/type/deleteCategory');
          }
          break;
        case "recon":
          {
            if (!selectData.length) return;
            this.handleBillStatus("启用", selectData, '/api/system/finance/rp/type/modifyStopStatus');
          }
          break;
        case "redoRecon":
          {
            if (!selectData.length) return;
            this.handleBillStatus("禁用", selectData, '/api/system/finance/rp/type/modifyStopStatus');
          }
          break;
        default:
          break;
      }
    },
    async handleBillStatus(command, selectData, url) {
      const rpTypeIds = selectData.map((i) => i.rpTypeId);
      const $this = this;
      let operate = await this.$modal.confirm(`是否确认${command}`)
      let params =  command == '删除'? {  rpTypeIds: [...rpTypeIds], url: url } : {  rpTypeIds: [...rpTypeIds],isStop: command == '禁用'? true : false , url: url } 
     
      updateReconStatus(params).then(() => {
        $this.$nextTick(() => { $this.options.check = [] });
        $this.$modal.msgSuccess(`${command}成功`);
        setTimeout(() => {
          $this.$refs.tablePage.getList();
        }, 500);
      });
    },
  },
  mounted () {
    this.getCategoryList(true)
  },
};
</script>
<style lang="scss" scoped>
.content {
  // height: 85vh;
  display: flex;
}
.left-tree {
  width: 25%;
  border: 1px solid #dfe6ec;
  padding: 10px;
  // height: calc(100% - 40px);
  // margin-top: 10px;、
  background-color: #eaeaea;
  .left-box {
    background: #fff;
    height: 100%;
    padding: 10px 10px 10px 10px;
    .treeTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .leftTitle {
        font-weight: 700;
        font-size: 14px;
      }
      .handleEdit {
        font-size: 14px;
        color: #1890ff;
        cursor: pointer;
      }
    }
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;

      .iconBtnTree {
        font-size: 14px;
        color: #999;
      }
    }
    .searchTreeBox {
      margin-top: 10px;
    }
  }
}
.right-table {
  flex: 1;
  width: calc(75% - 10px);
  // margin-left: 10px;
  .searchTableBox {
    display: flex;
    margin-top: 10px;
    .el-input {
      width: 30%;
      margin-right: 10px;
    }
  }
  .tableBox {
    margin-top: 10px;
  }
}

::v-deep .radioSelect {
  .el-radio__label {
    display: none !important;
  }

  .el-radio__original {
    z-index: 1 !important;
    cursor: pointer;
  }
}

::v-deep .select-all-filter {
  width: 350px !important;
  margin-right: 10px !important;
}
::v-deep .el-scrollbar {
  height: calc(100% - 38px);
  margin-top: 10px;
}
// 侧边滚动条显示
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  margin-bottom: 0 !important;
}
::v-deep .pagination-view {
  height: 50px;
}
</style>
